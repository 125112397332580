import type { IUseCookie } from '@application/composables/cookie'
import type { Address } from '@/domain/address'
import type { LinkLoyalty } from '@contexts/user/domain/link-loyalty.model'
import type { Loyalty } from '@/contexts/loyalty/domain/loyalty.model'
import { User } from '../domain/user.model'
import type { IUserRepository } from './interfaces/i-user.repository'
import type { IUserDatasource } from './interfaces/i-user.datasource'
import type { Optins } from '../domain/optins.model'
import { OptinsMapper } from './mappers/optins.mapper'

export class UserRepository implements IUserRepository {
  constructor(private readonly userDataSource: IUserDatasource, private generateId: () => string) {}

  async getCurrentUser(): Promise<User> {
    return this.userDataSource.getCurrentUser()
  }

  async postAddress(address: Address): Promise<Address> {
    return this.userDataSource.postAddress(address)
  }

  async deleteAddress(addressId: string): Promise<Address> {
    return this.userDataSource.deleteAddress(addressId)
  }

  async updateAddress(newAddress: Address): Promise<Address> {
    return this.userDataSource.updateAddress(newAddress)
  }

  async updateUserInformation(user: User): Promise<User> {
    return this.userDataSource.updateUserInformation(user)
  }

  async resetPassword(): Promise<void> {
    return this.userDataSource.resetPassword()
  }

  async toggleIsDefaultAddress(addressId: string): Promise<Address> {
    return this.userDataSource.toggleIsDefaultAddress(addressId)
  }

  async getAnonymousUser(cookie: IUseCookie): Promise<User> {
    const current = await cookie.getCookie<User>()
    if (current) return current

    const notConnectedId = `ens-${this.generateId()}`
    return User.generateNotConnectedUser(notConnectedId)
  }

  // eslint-disable-next-line class-methods-use-this
  async saveAnonymousUser(user: User, cookie: IUseCookie): Promise<void> {
    return cookie.setCookie<User>(user)
  }

  async linkLoyaltyCard(cardNumber: number, postalCode?: string): Promise<LinkLoyalty> {
    return this.userDataSource.linkToLoyalty(cardNumber, postalCode)
  }

  async getDefaultAddress(): Promise<Address> {
    return this.userDataSource.getDefaultAddress()
  }

  async getBillingAddress(): Promise<Address> {
    return this.userDataSource.getBillingAddress()
  }

  async updateOptins(optins: Optins): Promise<Optins> {
    const optinsPayload = OptinsMapper.toRequest(optins)
    return this.userDataSource.updateOptins(optinsPayload)
  }

  getCurrentLoyalty(): Promise<Loyalty> {
    return this.userDataSource.getCurrentUserLoyalty()
  }
}
