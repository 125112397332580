import { useCookie as useCookieNuxt } from '#imports'

export type IUseCookieGet = <T>() => T | null
export type IUseCookieSet = <T>(data: T) => void
export type IUseCookieRemove = () => void

export type IUseCookie = {
  getCookie: IUseCookieGet
  setCookie: IUseCookieSet
  removeCookie: IUseCookieRemove
}

export const useCookie = <T>(id: string): IUseCookie => {
  const expireDate = new Date()
  expireDate.setTime(expireDate.getTime() + 366 * 24 * 60 * 60 * 1000)
  const nuxtCookie = useCookieNuxt<T>(id, { expires: expireDate })

  const getCookie = <P = T>(): P | null => {
    return nuxtCookie.value || null
  }

  /**
   * @param id cookie id
   * @param data serialized json object
   * @returns void
   */
  const setCookie = <P = T>(data: P): void => {
    nuxtCookie.value = data
  }

  const removeCookie = (): void => {
    nuxtCookie.value = null
  }

  return { getCookie, setCookie, removeCookie }
}
