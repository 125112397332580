import { defineNuxtPlugin, ref, reloadNuxtApp, useRouter } from '#imports'
import type { LocaleCode } from '../composables/locale'

export interface PathState {
  [LocaleCode.FR]: string
  [LocaleCode.ES]: string
}

export default defineNuxtPlugin(() => {
  const pathState = ref<PathState>({} as PathState)
  const router = useRouter()

  const switchToLocalePath = async (locale: LocaleCode) => {
    reloadNuxtApp({
      path: pathState.value[locale]
    })
  }

  const updatePathState = (pState: PathState) => {
    pathState.value = pState
  }

  router.beforeEach((_to, _from, next) => {
    updatePathState({} as PathState)
    next()
  })

  return {
    provide: {
      pathState: {
        switchToLocalePath,
        updatePathState,
        state: pathState
      }
    }
  }
})
