import type { Hit } from '@algolia/client-search'
import { LogisticRoute } from '@invivodf/module-logistic-offer-client-sdk'
import type { Product, ProductPrice } from '@contexts/product/domain/product.interface'
import type { IProductAvailability } from '@domain/product/product.interface'
import { ProductDiscountScope } from '@domain/product/product.interface'
import { Utils } from '@/application/composables/utils'
import { ProductFamily } from '@invivoretail/module-catalog-sdk'
import type AlgoliaProduct from '../search/algolia-product'
import { AlgoliaProductFamily } from '../search/algolia-product'

const getPriceAndDiscountFromSearchResult = (
  selectedStoreId: string | undefined | null,
  algoliaProduct: Hit<AlgoliaProduct>,
  isFID: boolean
): ProductPrice => {
  const pricingForStore = algoliaProduct[`store-${selectedStoreId}`]

  const offerPrice = pricingForStore?.[isFID ? 'fid' : 'withoutFid']

  if (!pricingForStore || !offerPrice) {
    return {
      isIndicativePrice: true,
      price: algoliaProduct.indicativePrice
    }
  }
  const price =
    offerPrice.discount?.scope === ProductDiscountScope.GROUP ? pricingForStore.priceBeforeDiscount : offerPrice.price

  return {
    isIndicativePrice: false,
    price: price || algoliaProduct.indicativePrice,
    discount: offerPrice.hasDiscount
      ? {
          amount: offerPrice.discount?.value,
          unit: offerPrice.discount?.unit,
          originalPrice: pricingForStore.priceBeforeDiscount,
          segment: offerPrice.discount?.segment,
          scope: offerPrice.discount?.scope,
          minQuantity: offerPrice.discount?.minQuantity
        }
      : undefined
  }
}

export type ProductMapperOpts = {
  selectedStoreId: string | null
  isWebstore: boolean
  hasHighlightedName: boolean
  isFID: boolean
}

export const ProductFamilyConverter = {
  to: {
    [AlgoliaProductFamily.PRODUCT_MPN]: ProductFamily.RETAIL_MPN,
    [AlgoliaProductFamily.RETAIL_NATIONAL]: ProductFamily.RETAIL_NATIONAL,
    [AlgoliaProductFamily.MARKETPLACE]: ProductFamily.MARKETPLACE
  },

  from: {
    [ProductFamily.RETAIL_MPN]: AlgoliaProductFamily.PRODUCT_MPN,
    [ProductFamily.RETAIL_NATIONAL]: AlgoliaProductFamily.RETAIL_NATIONAL,
    [ProductFamily.MARKETPLACE]: AlgoliaProductFamily.MARKETPLACE
  }
}

const productMapper = (
  algoliaProduct: Hit<AlgoliaProduct>,
  { selectedStoreId, isWebstore, hasHighlightedName = false, isFID }: ProductMapperOpts
): Product => {
  const {
    objectID: id,
    _highlightResult: highlightResult,
    brand,
    categories,
    modelId,
    name,
    thumbnailUrl,
    slug,
    hasStockForHomeDelivery,
    storesWithStock,
    storesWithOrderableOnlineProduct,
    isSellableOnline,
    productReview,
    shopName,
    productFamily
  } = algoliaProduct

  const { isIndicativePrice, price, discount } = getPriceAndDiscountFromSearchResult(
    selectedStoreId,
    algoliaProduct,
    isFID
  )

  const hasStore = selectedStoreId != null

  const availabilities: IProductAvailability = {
    [LogisticRoute.HomeDelivery]: isSellableOnline && hasStockForHomeDelivery,
    ...(!isWebstore && {
      [LogisticRoute.ClickAndCollect]:
        isSellableOnline && hasStore ? storesWithOrderableOnlineProduct?.includes(selectedStoreId) : false
    })
  }

  // eslint-disable-next-line no-underscore-dangle
  const meta = algoliaProduct.__queryID ? { queryId: algoliaProduct.__queryID } : undefined

  return {
    id,
    brand,
    categories,
    image: {
      description: brand ? `${brand} - ${name}` : name,
      sources: [{ url: thumbnailUrl }]
    },
    isIndicativePrice,
    modelId,
    name: hasHighlightedName ? highlightResult!.name!.value : name,
    isSellableOnline,
    ...(hasStore && { availability: availabilities, isInStockInStore: storesWithStock?.includes(selectedStoreId) }),
    price,
    discount,
    slug,
    meta,
    productReview,
    shopName,
    shopNameSlug: shopName ? Utils.slugify(shopName) : undefined,
    isFromMarketplace: productFamily === AlgoliaProductFamily.MARKETPLACE,
    productFamily: ProductFamilyConverter.to[productFamily]
  }
}

export const mapProductWithHighlightedName = (
  algoliaProduct: Hit<AlgoliaProduct>,
  selectedStoreId: string | null,
  isWebstore: boolean,
  isFID: boolean
): Product => {
  return productMapper(algoliaProduct, {
    selectedStoreId,
    isWebstore,
    hasHighlightedName: true,
    isFID
  })
}

export const mapProduct = (
  algoliaProduct: Hit<AlgoliaProduct>,
  selectedStoreId: string | null,
  isWebstore: boolean,
  isFID: boolean
): Product => {
  return productMapper(algoliaProduct, { selectedStoreId, isWebstore, hasHighlightedName: false, isFID })
}
